.side-menu-button {
  box-shadow: 0px 4px 45px rgba(253, 187, 45, 0.7);
}

.add-bottom {
  box-shadow: 0px 0px 25px rgba(253, 187, 45, 0.47);
}
.admin-search {
  border: 1px solid white;
  border-radius: 5px;
}
.admin-search > input[type='text'] {
  padding-left: 0.33rem;
  padding-top: 0.33rem;
  padding-bottom: 0.33rem;
  background-color: transparent;
  outline: none;
  width: 100%;
  height: 3rem;
  color: white;
}

.labelborder {
  border-bottom: 1px solid rgba(4, 16, 24, 0.4);
}

.title-input > input[type='text'] {
  padding: 0.33rem;
  outline: none;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  border-radius: 5px;
  height: 50px;
}

.title-input > input[type='text']::placeholder {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 114%;
}

.rotate-arrow {
  transform: rotateY(180deg);
}

.description-area > textarea {
  width: 100%;
  padding: 0.33rem;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  border-radius: 5px;
  height: 150px;
}

.description-area > textarea::placeholder {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 114%;
}

.upload-image-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgba(255, 255, 255, 0.6)' stroke-width='3' stroke-dasharray='10' stroke-dashoffset='14' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 5px;
}
.scroll-style {
  overflow-y: auto;
  height: calc(100vh - 70px);
}

.scroll-style::-webkit-scrollbar-track {
  /* border: 1px solid #000;
  padding: 2px 0; */
  background-color: black;
}

.scroll-style::-webkit-scrollbar {
  width: 3px;
}

.scroll-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  border: 1px solid #000;
}

.color-picker {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='rgba(255, 255, 255, 0.6)' stroke-width='4' stroke-dasharray='19%2c 11' stroke-dashoffset='81' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 100px;
}

.color-picker-set {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='rgba(255, 255, 255, 0.6)' stroke-width='4' stroke-dasharray='19%2c 11' stroke-dashoffset='81' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 100px;
}

.upload-cover-photo {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgba(255, 255, 255, 0.6)' stroke-width='3' stroke-dasharray='10' stroke-dashoffset='14' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 5px;
}