.hover-image {
  opacity: 0.5;
}

.before-hover-info {
  display:none;
}

.hover-info {
  position: relative;
  z-index: 10000;
  margin-top: -9rem;
  display: block;
}
.explore-button{
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}