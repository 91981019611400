.stocks-image{
    padding-left: 6rem;
}

.stocks-text{
    padding-left: 6rem;
}

.stocks-text-md-sm{
    padding-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 3rem;
}