.vertical {
    width: 0.2rem;
    height: 1rem;
    background-color: white;
    margin-top: -0.55rem;
    margin-left: 0.4rem;

}

.horizontal {
    width: 1rem;
    height: 0.2rem;
    background-color: white;
}

.opened {
    opacity: 1;
}

.opened .vertical {
    transition: all 0.2s ease-in-out;
    transform: rotate(90deg);
}

.opened .horizontal {
    transition: all 0.2s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
}

.closed .vertical {
    transition: all 0.2s ease-in-out;
    transform: rotate(-90deg);
}

.closed .horizontal {
    transition: all 0.2s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
}
