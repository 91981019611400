.faq-button {
  border-radius: 5px;
}
.nav-element-border {
  border-bottom: 1px solid rgba(4, 16, 24, 0.4);
}

.nav-element-padding {
  padding-left: 0.5rem;
  padding-right: 2rem;
}

.arrow-padding {
  padding-left: 2rem;
  padding-right: 0rem;
}

.if-sub-list {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.mobile-nav-dropdown {
  padding-top: 0.3rem;
  padding-left: 1.5rem;
  padding-right: 1rem;
}

.mobile-nav-mainList {
  padding-top: 2rem;
  padding-left: 1.5rem;
}

.mobile-nav-arrow {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  padding-right: 1.85rem;
}

.mobile-nav-sub-arrow {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.75rem;
}
