.sub-title {
  font-style: normal;
  font-size: 25px;
  line-height: 114%;
}
.line {
  width: 18%;
  height: 0.2rem;
  background-color: white;
}

