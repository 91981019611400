.features-card{
width: 280px;
height: 210px;
border: 1px solid #E4B43F;;
box-sizing: border-box;

}

.icon-circle{
    background-image: linear-gradient(179deg, rgba(255, 181, 0, 0.5) 0%, rgb(23 23 21) 100%);
}