.search-input {
  /* background-color: "linear-gradient(97.11deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.2) 100%) !important"; */
  border-radius: 15px;
  background-image: linear-gradient(
    97.11deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
}

.search-input-home {
  /* background-color: "linear-gradient(97.11deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.2) 100%) !important"; */
  border-radius: 15px;
}

.search-input-dropdown-open {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-image: linear-gradient(
    97.11deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
}

.search-input > input[type='text'] {
  background: transparent;
  border: none;
  padding: 0.75rem;
}
.search-input-home > input[type='text'] {
  background: transparent;
  border: none;
  padding: 0.75rem;
}

.search-input-dropdown-open > input[type='text'] {
  background: transparent;
  border: none;
  padding: 0.75rem;
}

.select-element {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  padding-right: 0.75rem;
  /* padding-top:0.7rem; */
  padding-left: 0.75rem;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.3);
}

.select-element-dropdown-open {
  border-top-left-radius: 14px;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.3);
}

.dropdown-background {
  background-image: linear-gradient(
    97.11deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
