.stories-card {
  padding: 1.35rem;
  border-radius: 0.625rem;
  height: 27.875rem;
}

.slider-nav-right {
  background-image: linear-gradient(
    135deg,
    #ffae00 0.48%,
    rgba(228, 180, 63, 0.6) 99.52%
  ) !important;
  box-shadow: -9px 2px 26px rgba(0, 0, 0, 0.35);
}

.slider-nav-left {
  background: linear-gradient(
    135deg,
    rgba(255, 174, 0, 0.5) 0.48%,
    rgba(228, 180, 63, 0.3) 99.52%
  );
  box-shadow: -9px 2px 26px rgba(0, 0, 0, 0.35);
}

.feedback-input > input[type='text'] {
  border-radius: 10px;
  border: none;
  padding: 0.35rem;
  padding-left: 1rem;
  background: linear-gradient(
    103.13deg,
    rgba(255, 255, 255, 0.8) -2.72%,
    rgba(255, 255, 255, 0.1) 102.27%
  );
}

.feedback-input > input::placeholder {
  font-style: italic;
  color: #04101880;
}

.feedback-input > textarea {
  border-radius: 10px;
  border: none;
  padding: 0.35rem;
  padding-left: 1rem;
  height: 10rem;
  background: linear-gradient(
    103.13deg,
    rgba(255, 255, 255, 0.8) -2.72%,
    rgba(255, 255, 255, 0.1) 102.27%
  );
}

.feedback-input > textarea::placeholder {
  font-style: italic;
  color: #04101880;
}

.story-image {
  border: 5px solid #e4b43f;
}

.slider {
  display: flex;
  flex-direction: column;
}

.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
}

.carousel-content {
  display: flex;
  column-gap: 40px;
  transition: all 1000ms linear;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}

.latest-stories-line {
  width: 70%;
  height: 0.2rem;
  background-color: white;
}

dt {
  font-weight: bold;
  padding-bottom: 2px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

dd {
  padding-left: 15px;
}

.factors > p {
  padding-top: 1.25rem;
}

.factors > h1 {
  font-weight: bold;
  padding-top: 1.25rem;
}

.ev-scenario > p{
  padding-top: 1.25rem;
}

.ev-sales > p {
  padding-top: 1.25rem;
}

.ev-future > p{
  padding-top: 1.25rem;
}

.conclusion > p{
  padding-top: 1.25rem;
}