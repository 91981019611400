.search_icon_border {
  background: black;
  border-radius: 15px;
  height: 40px;
  width: 40px;
}

.line-faq {
  width: 10rem;
  height: 0.2rem;
  background-color: black;
}

.catagories-card{
  background-image: linear-gradient(179.81deg, rgba(255, 255, 255, 0.9) -6.41%, rgba(255, 255, 255, 0.1) 105.9%);
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.5), 0px 2px 70px rgba(255, 255, 255, 0.35);
  border-radius: 15px;
}

.line-cat{
  width: 15rem;
  height: 0.2rem;
  background-color: white;
}